@import "~@cf-design-system/tokens/constants.scss";

.account {
  .account-body {
    margin: 0 auto var(--space-unit-xxl);
    max-width: $breakpoint-mobile-2;
    padding: var(--space-unit-xxxl);

    @media (max-width: $breakpoint-mobile-2) {
      margin-bottom: var(--space-unit-xl);
      padding: var(--space-unit-xl);
    }

    .title {
      margin-bottom: var(--space-unit-lg);
    }

    .change-password {
      max-width: 320px;

      .change-password-input {
        margin-bottom: var(--space-unit-lg);
      }

      .change-password-input-error {
        margin-bottom: var(--space-unit-sm);
      }

      .change-password-button {
        margin-bottom: var(--space-unit-lg);

        svg {
          margin-left: var(--space-unit-xs);
        }
      }
    }

    .actions-divider {
      opacity: 0.25;
    }

    .actions {
      display: flex;
      margin-top: var(--space-unit-lg);

      @media (max-width: $breakpoint-mobile-1) {
        flex-direction: column;
      }

      & > * {
        flex: 1;

        &:last-child {
          margin-left: var(--space-unit-md);

          @media (max-width: $breakpoint-mobile-1) {
            margin-left: 0;
            margin-top: var(--space-unit-md);
          }
        }
      }

      .save-button {
        display: flex;
        width: 100%;

        button {
          flex: 1;
        }
      }
    }
  }
  .account-header {
    margin: auto;
    max-width: 900px;
    margin-bottom: var(--space-unit-xl);
  }
}
