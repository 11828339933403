@import "~@cf-design-system/tokens/constants.scss";

.footer {
  bottom: 0;
  display: flex;
  justify-content: space-between;
  // margin-bottom: var(--space-unit-lg);
  position: absolute;
  height: auto;
  width: 100%;
  background: #006fb7;
  color: #fff;

  @media (max-width: $breakpoint-mobile-1) {
    align-items: center;
  }
  .footer-icon {
    margin: var(--space-unit-xs);
  }
  .footer-links {
    display: flex;
    flex-direction: column;
    margin: var(--space-unit-xl);
  }
  .footer-link {
    &:first-child {
      margin-bottom: var(--space-unit-lg);
    }

    a {
      color: inherit;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
