@import "~@cf-design-system/tokens/constants.scss";

.privacy-policy {
  .privacy-policy-header {
    margin: auto;
    max-width: 900px;
    margin-bottom: var(--space-unit-xl);
  }
  .privacy-policy-body {
    margin: 0 auto var(--space-unit-xxl);
    max-width: $breakpoint-mobile-2;
    padding: var(--space-unit-xxl);

    @media (max-width: $breakpoint-mobile-2) {
      margin-bottom: var(--space-unit-xl);
      padding: var(--space-unit-xl);
    }

    .title {
      margin: var(--space-unit-lg) 0;
    }

    .actions-divider {
      margin: var(--space-unit-lg) 0;
      opacity: 0.25;
    }

    .agree-button {
      display: flex;
      width: calc(50% - var(--space-unit-xs));

      @media (max-width: $breakpoint-mobile-1) {
        width: 100%;
      }

      button {
        flex: 1;
      }
    }
  }
}
