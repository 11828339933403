@import "~@cf-design-system/tokens/constants.scss";

.silo-details {
  .silo-details-body {
    margin: 0 auto var(--space-unit-xxl);
    max-width: $breakpoint-mobile-2;
    padding: var(--space-unit-xxxl);

    @media (max-width: $breakpoint-mobile-2) {
      margin-bottom: var(--space-unit-xl);
      padding: var(--space-unit-xl);
    }

    .silo-details-info {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: var(--space-unit-xxl);

      @media (max-width: $breakpoint-mobile-2) {
        align-items: flex-start;
        flex-direction: column;
      }

      .silo-details-info-left {
        align-items: center;
        display: flex;

        .silo-details-measures {
          align-items: flex-start;
          display: flex;
          flex: 1;
          flex-direction: column;
          margin-left: var(--space-unit-lg);

          .silo-details-volume-pct {
            margin-bottom: var(--space-unit-xs);
          }

          .silo-details-days-left {
            background-color: var(--color-white);
            border-radius: var(--space-unit-md);
            border: 1px solid var(--color-grey-grey100);
            margin-top: var(--space-unit-xs);
            padding: var(--space-unit-xxs) var(--space-unit-xs);

            &.danger {
              background-color: var(--color-alert-alert60);
              border-color: var(--color-alert-alert60);
            }

            &.warning {
              background-color: var(--color-warning-warning50);
              border-color: var(--color-warning-warning50);
            }
          }
        }
      }

      .silo-details-info-right {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        @media (max-width: $breakpoint-mobile-2) {
          margin-top: var(--space-unit-xxl);
        }

        .silo-details-info-item {
          align-items: center;
          display: flex;
          margin-bottom: var(--space-unit-md);

          &:last-child {
            margin-bottom: 0;
          }

          .silo-details-info-item-icon {
            margin-right: var(--space-unit-xs);
          }
        }
      }
    }

    .silo-chart-wrapper {
      .silo-chart-header {
        display: flex;
        justify-content: space-between;

        @media (max-width: $breakpoint-mobile-2) {
          flex-direction: column;
        }

        .silo-chart-title {
          display: flex;
          flex-direction: column;
          margin-bottom: var(--space-unit-lg);
        }
      }

      .silo-chart-info {
        display: inline-block;
        margin-top: var(--space-unit-xs);
      }

      .silo-chart-legend {
        display: flex;
        justify-content: flex-end;
        margin-top: var(--space-unit-xs);
        margin-bottom: var(--space-unit-xl);

        .silo-chart-legend-item {
          align-items: center;
          display: flex;
          margin-right: var(--space-unit-md);

          &:last-child {
            margin-right: 0;
          }

          .silo-chart-legend-color {
            border-radius: 25%;
            height: var(--space-unit-md);
            margin-right: var(--space-unit-xxs);
            width: var(--space-unit-md);

            &.delivery {
              background-color: var(--color-blue-blue100);
            }

            &.weekends {
              background-color: var(--color-grey-grey50);
            }
          }
        }
      }

      .silo-chart {
        text-align: start;

        @media (max-width: $breakpoint-mobile-2) {
          padding: 0 var(--space-unit-md);
        }

        // Override apexchart styles.
        .apexcharts-tooltip {
          .apexcharts-tooltip-title {
            background: none;
            border-bottom: none;
            font-size: 13px !important;
            font-weight: bold;
            padding-left: 10px;
          }
        }
      }
    }

    .silo-alarms {
      .silo-alarms-header {
        display: flex;
        justify-content: space-between;
      }
    }

    .silo-alarms-section {
      display: flex;
      flex-direction: column;
      margin-top: var(--space-unit-lg);

      .silo-alarm {
        align-items: center;
        display: flex;

        .silo-alarm-icon {
          margin-right: var(--space-unit-xs);
        }
      }

      .silo-alarm-contacts {
        display: flex;
        flex-direction: column;
        margin-left: calc(var(--space-unit-xl) + var(--space-unit-xs));
        margin-top: var(--space-unit-xs);

        .silo-alarm-contacts-item {
          color: var(--color-grey-darker);
          margin-bottom: var(--space-unit-xs);

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .no-alarms-text {
      color: var(--color-grey-grey100);
      display: block;
    }
    .silo-details-divider {
      margin-bottom: var(--space-unit-xxl);

      @media (max-width: $breakpoint-mobile-2) {
        margin-bottom: var(--space-unit-xl);
      }
    }
  }
}

.time-period-dropdown {
  min-width: 150px !important;
}
.silo-details-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  user-select: none;
  max-width: 900px;
  margin: 0 auto;

  @media (max-width: $breakpoint-mobile-2) {
    .button-edit-info {
      margin-right: var(--space-unit-md);
    }
  }
}
