@import "~@cf-design-system/tokens/constants.scss";

.page-header {
  max-width: $breakpoint-mobile-2;
  margin-bottom: var(--space-unit-lg);

  @media (max-width: $breakpoint-mobile-2) {
    max-width: none;
    min-width: auto;
    padding: 0 var(--space-unit-md);
  }

  .page-header-grid {
    align-items: center;

    @media (max-width: $breakpoint-mobile-2) {
      grid-template-columns: auto auto;
    }

    .back-button {
      align-items: center;
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      grid-column: 1;
      margin-bottom: var(--space-unit-xs);
      padding: 0;
      transform: translate(-5px, 0); // nudge button to the left to visually align with rest of page
      width: fit-content;
    }

    .page-title {
      grid-column: 2;

      @media (max-width: $breakpoint-mobile-2) {
        grid-column: 1 / 3;
        grid-row: 2;
        justify-self: center;
      }

      @media (max-width: $breakpoint-mobile-1) {
        justify-self: flex-start;
      }
    }

    .user-menu {
      display: flex;
      grid-column: 3;
      justify-content: flex-end;
      min-width: 0;

      @media (max-width: $breakpoint-mobile-2) {
        grid-column: 2;
      }
    }
  }

  .page-subtitle {
    display: inline-block;
    margin-top: var(--space-unit-xs);
  }
}
