@import "~@cf-design-system/tokens/constants.scss";

.order-history {
  margin: 0 auto var(--space-unit-xxl);
  max-width: $breakpoint-mobile-2;
  padding: var(--space-unit-xxxl) 0;

  @media (max-width: $breakpoint-mobile-2) {
    margin-bottom: var(--space-unit-xl);
    padding: var(--space-unit-xl) 0;
  }

  .title {
    margin: 0 var(--space-unit-xxxl);

    @media (max-width: $breakpoint-mobile-2) {
      margin: 0 var(--space-unit-xl);
    }

    &:not(:only-child) {
      font-weight: 500;
      margin-bottom: var(--space-unit-lg);
    }
  }

  .table {
    border-spacing: 0;
    padding: 0 var(--space-unit-xxxl);
    width: 100%;

    @media (max-width: $breakpoint-mobile-2) {
      padding: 0 var(--space-unit-xl);
    }

    thead {
      th {
        padding: 0 var(--space-unit-md) var(--space-unit-xs);
        text-align: left;
        text-transform: capitalize;
        vertical-align: bottom;
      }

      td {
        padding: 0;
      }
    }

    tbody {
      tr:nth-child(even) {
        background-color: var(--color-grey-grey25);
      }

      td {
        padding: var(--space-unit-xs) var(--space-unit-md);
      }
    }

    .header-divider {
      opacity: 25%;
    }
  }
}
