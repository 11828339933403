@import "~@cf-design-system/tokens/constants.scss";

.header {
  display: flex;
  flex-direction: column;
  margin: var(--space-unit-md) auto var(--space-unit-xxxl);
  max-width: calc(100% - (var(--space-unit-xxl) * 2));
  min-width: $breakpoint-mobile-2;

  @media (max-width: $breakpoint-mobile-2) {
    margin: var(--space-unit-md) var(--space-unit-md) var(--space-unit-xxl);
    max-width: none;
    min-width: auto;
  }

  .header-grid {
    align-items: center;
    display: grid;
    gap: var(--space-unit-md);
    grid-auto-flow: column;
    grid-template-columns: 1fr auto 1fr;

    @media (max-width: $breakpoint-mobile-2) {
      grid-template-columns: auto auto;
    }

    .logo {
      cursor: pointer;
    }

    .back-button {
      align-items: center;
      background: none;
      border: none;
      cursor: pointer;
      display: flex;
      grid-column: 1;
      padding: 0;
      width: fit-content;
    }

    .title {
      grid-column: 2;

      @media (max-width: $breakpoint-mobile-2) {
        grid-column: 1 / 3;
        grid-row: 2;
        justify-self: center;
      }

      @media (max-width: $breakpoint-mobile-1) {
        justify-self: flex-start;
      }
    }

    .user-menu {
      display: flex;
      grid-column: 3;
      justify-content: flex-end;
      min-width: 0;
      align-items: center;

      @media (max-width: $breakpoint-mobile-2) {
        grid-column: 2;
      }

      .user-menu-popover {
        margin: 0;
        min-width: 0;

        .user-menu-wrapper {
          align-items: center;
          display: flex;
          justify-content: flex-end;

          .user-menu-icon {
            align-items: center;

            justify-content: center;
            margin-left: var(--space-unit-xs);
            padding: var(--space-unit-xs);
          }
        }
      }
      .header-store-link {
        @media (max-width: $breakpoint-mobile-2) {
          margin-right: var(--space-unit-lg);
        }
      }
    }
  }

  .header-subtitle {
    margin-bottom: calc((var(--space-unit-lg) + var(--space-unit-md)) * -1);
    margin-top: var(--space-unit-md);
    text-align: center;

    @media (max-width: $breakpoint-mobile-1) {
      text-align: unset;
    }
  }
}

.user-menu-popover {
  margin-top: var(--space-unit-xl);
  margin-right: var(--space-unit-md);
  max-width: calc(100% - var(--space-unit-xl));

  @media (max-width: $breakpoint-mobile-2) {
    margin-top: var(--space-unit-md);
  }

  .user-menu-username {
    user-select: none;
  }
}
