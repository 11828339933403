@import "~@cf-design-system/tokens/constants.scss";
@import "~@cf-design-system/tokens/index.scss";

@font-face {
  font-family: "SourceSansPro";
  src: local("SourceSansPro"),
    url(./assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "SourceSansPro";
  font-style: italic;
  src: local("SourceSansPro-Italic"),
    url(./assets/fonts/SourceSansPro/SourceSansPro-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "SourceSansPro";
  font-weight: bold;
  src: local("SourceSansPro-Bold"),
    url(./assets/fonts/SourceSansPro/SourceSansPro-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "SourceSansPro";
  font-weight: 600;
  src: local("SourceSansPro-SemiBold"),
    url(./assets/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "SourceSansPro";
  font-style: italic;
  font-weight: bold;
  src: local("SourceSansPro-BoldItalic"),
    url(./assets/fonts/SourceSansPro/SourceSansPro-BoldItalic.ttf) format("truetype");
}

html {
  font-size: var(--font-size);
  height: 100%;
}

body {
  font-family: "SourceSansPro", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  height: 100%;
  margin: unset;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1,
h2 {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  overflow: auto;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
}

#app {
  min-height: 100%;
  overflow: hidden;
  // offset absolutely positioned footer's height
  padding-bottom: 121px !important;
  position: relative;

  @media (max-width: $breakpoint-mobile-1) {
    // offset absolutely positioned footer's height
    padding-bottom: 121px !important;
  }
}

* {
  box-sizing: border-box;
}
