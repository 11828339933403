@import "~@cf-design-system/tokens/constants.scss";

.silos-header {
  margin: auto;
  max-width: 900px;
}

.silos {
  margin: 0 auto var(--space-unit-xxl);
  max-width: $breakpoint-mobile-2;

  @media (max-width: $breakpoint-mobile-2) {
    margin-bottom: var(--space-unit-xl);
    padding: 0 var(--space-unit-md);
  }

  .silos-tab-group {
    margin: var(--space-unit-lg) 0 var(--space-unit-xl) 0;
    user-select: none;

    @media (max-width: $breakpoint-mobile-2) {
      justify-content: center;
    }

    @media (max-width: $breakpoint-mobile-1) {
      justify-content: unset;
    }
  }

  .legend {
    display: flex;
    margin-bottom: var(--space-unit-lg);
    padding: 0;

    @media (max-width: $breakpoint-mobile-2) {
      justify-content: center;
    }

    @media (max-width: $breakpoint-mobile-1) {
      justify-content: unset;
    }
  }

  .silos-list {
    display: grid;
    gap: var(--space-unit-lg);
    grid-template-columns: 1fr 1fr;

    @media (max-width: $breakpoint-mobile-1) {
      gap: var(--space-unit-md);
      grid-template-columns: 1fr;
    }

    .silo {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      height: 100%;
      justify-content: space-between;
      min-width: 0;
      padding: var(--space-unit-md);
      width: 100%;

      .silo-info {
        display: flex;
        flex-direction: column;
        min-width: 0;

        .silo-info-item {
          align-items: center;
          display: flex;
          flex-direction: row;

          &:not(:last-child) {
            margin-bottom: var(--space-unit-xs);
          }

          .silo-info-name {
            font-size: var(--font-size);
            line-height: var(--font-size);
          }

          .silo-info-icon {
            align-items: center;
            height: 20px;
            width: 20px;
          }

          .silo-info-text {
            flex: 1;
            line-height: normal;
            margin-left: var(--space-unit-xs);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &.silo-custom-name,
            &.silo-info-no-alarms,
            &.silo-info-no-feed {
              color: var(--color-grey-grey100);
            }
          }
        }
      }

      .silo-summary {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-self: flex-end;
        margin-left: var(--space-unit-sm);

        .silo-volume-pct {
          background-color: var(--color-white);
          border-radius: var(--space-unit-md);
          margin-bottom: var(--space-unit-xs);
          padding: var(--space-unit-xxs) var(--space-unit-sm);

          &.danger {
            background-color: var(--color-alert-alert60);
          }

          &.warning {
            background-color: var(--color-warning-warning50);
          }
        }
      }
    }
  }
}
.silo-message {
  margin: var(--space-unit-xxl) auto;
  max-width: $breakpoint-mobile-2;

  @media (max-width: $breakpoint-mobile-2) {
    margin-top: var(--space-unit-xl);
    padding: 0 var(--space-unit-md);
  }
}
