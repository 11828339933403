@import "~@cf-design-system/tokens/constants.scss";

.farms {
  .farms-list {
    position: relative;
    max-width: $breakpoint-mobile-2;
    margin: 0 auto var(--space-unit-xl);

    .farms-tab-group {
      margin-bottom: var(--space-unit-xl);
      user-select: none;

      @media (max-width: $breakpoint-mobile-2) {
        margin-bottom: 78px; // Account for space occupied by absolutely positioned search input
        padding: 0 var(--space-unit-md);
      }

      @media (max-width: $breakpoint-mobile-1) {
      }
    }

    .farms-body-search {
      box-sizing: border-box;
      margin: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 343px;

      @media (max-width: $breakpoint-mobile-2) {
        left: var(--space-unit-md);
        right: var(--space-unit-md);
        top: 50px;
        width: auto;
      }
    }
  }

  .farms-body {
    margin: 0 auto var(--space-unit-xxl);
    max-width: $breakpoint-mobile-2;

    @media (max-width: $breakpoint-mobile-2) {
      padding: 0 var(--space-unit-md);
    }

    .farm {
      align-items: center;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: var(--space-unit-xl) var(--space-unit-xxxl);
      width: 100%;

      &:not(:last-child) {
        margin-bottom: var(--space-unit-lg);

        @media (max-width: $breakpoint-mobile-1) {
          margin-bottom: var(--space-unit-md);
        }
      }

      @media (max-width: $breakpoint-mobile-2) {
        align-items: flex-start;
        flex-direction: column;
        padding: var(--space-unit-xl);
      }

      .farm-title {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
      }

      .farm-address {
        margin-top: var(--space-unit-xs);
      }

      .farm-silos {
        align-items: center;
        display: flex;
        flex-direction: column;

        @media (max-width: $breakpoint-mobile-2) {
          align-items: flex-start;
        }

        .farm-silos-title {
          margin-bottom: var(--space-unit-xs);

          @media (max-width: $breakpoint-mobile-2) {
            margin-top: var(--space-unit-xl);
          }
        }

        .farm-silos-icon-wrapper {
          align-items: center;
          display: flex;

          .farm-silos-icon {
            align-items: center;
            height: var(--space-unit-xl);
            justify-content: center;
            margin-right: var(--space-unit-xs);
            width: var(--space-unit-xl);
          }
        }
      }
    }
  }
  .farms-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    margin: 0 auto var(--space-unit-lg);
  }
}
