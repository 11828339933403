@import "~@cf-design-system/tokens/constants.scss";

.map {
  margin: 0 auto var(--space-unit-xxl);
  max-width: $breakpoint-mobile-2;

  @media (max-width: $breakpoint-mobile-2) {
    margin-bottom: var(--space-unit-xl);
  }
}

#map-id {
  border-radius: 40px;
  z-index: 5;

  .leaflet-control-container {
    .leaflet-bottom.leaflet-right {
      margin: 0 20px;
    }

    .leaflet-top.leaflet-right {
      margin: 0 20px;

      .leaflet-control {
        margin: 0;
      }
    }
  }

  .leaflet-div-icon {
    background: none;
    border: none;
  }

  .marker {
    align-items: center;
    display: flex;
    flex-direction: column;

    .marker-label {
      background-color: var(--color-white);
      border-radius: 90px;
      margin-bottom: var(--space-unit-xxs);
      padding: var(--space-unit-xxs) var(--space-unit-xs);

      &.danger {
        background-color: var(--color-alert-alert60);
      }

      &.warning {
        background-color: var(--color-warning-warning50);
      }
    }
  }

  .marker-popup {
    display: flex;
    flex-direction: column;

    .marker-popup-title {
      align-items: center;
      display: flex;
      margin-bottom: var(--space-unit-xs);

      .marker-popup-main-title {
        font-size: var(--font-size);
        line-height: var(--font-size);
      }

      .marker-popup-subtitle {
        color: var(--color-grey-grey100);
        flex: 1;
        margin-left: var(--space-unit-xs);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    & > :not(:first-child) {
      margin-top: var(--space-unit-xs);
    }
  }

  .farm-label {
    display: none;
    text-align: center;

    .detailed & {
      display: inline-block;
    }
  }
}

.cluster-outer {
  align-items: center;
  background-color: var(--color-grey-grey50);
  background-clip: padding-box;
  border-radius: 50%;
  display: flex;
  height: 100%;
  justify-content: center;

  &.danger {
    background-color: var(--color-alert-alert40);
  }

  &.warning {
    background-color: var(--color-warning-warning25);
  }

  .cluster-inner {
    align-items: center;
    background-color: var(--color-white);
    border-radius: 50%;
    display: flex;
    height: 40px;
    justify-content: center;
    text-align: center;
    width: 40px;

    &.danger {
      background-color: var(--color-alert-alert80);
    }

    &.warning {
      background-color: var(--color-warning-warning50);
    }
  }
}

.legend {
  background-color: var(--color-white);
  border-radius: 20px;
  display: inline-flex;
  margin-top: var(--space-unit-md);
  padding: var(--space-unit-md);

  .legend-item {
    align-items: center;
    display: flex;

    &:not(:first-child) {
      flex: 0 0 auto;
    }

    &:not(:last-child) {
      margin-right: var(--space-unit-sm);
    }

    .cluster-outer {
      height: 16px;
      margin-right: var(--space-unit-xxs);
      width: 16px;

      .cluster-inner {
        height: 12px;
        width: 12px;
      }
    }
  }
}
