.login {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 122px);
  justify-content: center;
  margin: auto;
  max-width: 320px;

  .logo {
    flex: 0 1 120px;
    margin-bottom: var(--space-unit-xl);
  }

  .title {
    align-self: center;
    margin-bottom: var(--space-unit-xl);
  }

  .form {
    height: 300px;
  }

  .input-username {
    margin-bottom: var(--space-unit-lg);
  }

  .input-password {
    margin-bottom: var(--space-unit-xl);
  }

  .actions {
    display: flex;
    flex-direction: column;

    & > :last-child {
      margin-top: var(--space-unit-sm);
    }

    .primary-button {
      display: flex;
      width: 100%;

      button {
        flex: 1;
      }
    }
  }
}
