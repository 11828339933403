@import "~@cf-design-system/tokens/constants.scss";

.edit-silo-info {
  .edit-silo-info-header {
    margin: auto;
    max-width: 900px;
    margin-bottom: var(--space-unit-xl);
  }
  .edit-silo-info-body {
    margin: 0 auto var(--space-unit-xxl);
    max-width: $breakpoint-mobile-2;
    padding: var(--space-unit-xxl);

    @media (max-width: $breakpoint-mobile-2) {
      margin-bottom: var(--space-unit-xl);
      padding: var(--space-unit-xl);
    }

    .title {
      margin-bottom: var(--space-unit-lg);
    }

    .edit-silo-info-profile {
      max-width: 320px;

      .edit-silo-info-profile-input {
        margin-bottom: var(--space-unit-xl);
      }
    }

    .edit-silo-info-feed {
      max-width: 320px;

      .edit-silo-info-feed-input {
        margin-bottom: var(--space-unit-xl);
      }
    }

    .edit-silo-info-animals {
      align-items: center;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: var(--space-unit-lg);

      & > :nth-child(n) {
        margin-bottom: var(--space-unit-xs);
      }

      .edit-silo-info-animals-input {
        margin-right: var(--space-unit-xs);
        width: 100px;
      }

      .edit-silo-info-animals-dropdown {
        margin-right: var(--space-unit-xs);
        min-width: 180px;
      }
    }

    .actions {
      display: flex;

      @media (max-width: $breakpoint-mobile-1) {
        flex-direction: column;
      }

      & > * {
        flex: 1;

        &:last-child {
          margin-left: var(--space-unit-md);

          @media (max-width: $breakpoint-mobile-1) {
            margin-left: 0;
            margin-top: var(--space-unit-md);
          }
        }
      }

      .save-button {
        display: flex;
        width: 100%;

        button {
          flex: 1;
        }
      }
    }
    .edit-silo-info-divider {
      margin-bottom: var(--space-unit-lg);
    }
  }
}
