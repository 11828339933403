@import "~@cf-design-system/tokens/constants.scss";

.alarms {
  .alarm-page-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    max-width: 900px;
    margin: 0 auto var(--space-unit-lg);
  }

  .alarms-body {
    margin: var(--space-unit-xl) auto var(--space-unit-xxl);
    max-width: $breakpoint-mobile-2;
    padding: var(--space-unit-xxxl);

    @media (max-width: $breakpoint-mobile-1) {
      margin-bottom: var(--space-unit-xl);
      padding: var(--space-unit-xl);
    }

    .alarm-list-header {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      margin-top: var(--space-unit-md);

      & > span {
        margin: var(--space-unit-xs) var(--space-unit-xs) 0 0;
      }

      .alarm-list-header-value {
        align-items: center;
        display: inline-flex;
        flex-wrap: wrap;

        & > * {
          margin-top: var(--space-unit-xs);
        }

        .alarm-list-header-input {
          margin-right: var(--space-unit-xs);
          width: var(--space-unit-xxxl);
        }
      }
    }

    .alarm-list {
      display: flex;
      flex-direction: column;
      margin-top: var(--space-unit-lg);
      padding-left: var(--space-unit-xxxl);

      @media (max-width: $breakpoint-mobile-2) {
        padding-left: var(--space-unit-xl);
      }

      @media (max-width: $breakpoint-mobile-1) {
        padding-left: 0;
      }

      .alarm-list-item-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: var(--space-unit-lg);

        @media (max-width: $breakpoint-mobile-1) {
          flex-direction: column;
        }

        .alarm-list-item {
          align-items: center;
          display: flex;

          @media (max-width: $breakpoint-mobile-1) {
            align-items: flex-start;
            flex-direction: column;
          }

          .alarm-list-item-section {
            align-items: center;
            display: flex;
            margin-right: var(--space-unit-xs);

            &:last-child {
              margin-right: 0;
            }

            @media (max-width: $breakpoint-mobile-1) {
              margin-bottom: var(--space-unit-lg);
              margin-right: 0;
              width: 100%;
            }

            & > * {
              margin-right: var(--space-unit-xs);

              &:last-child {
                margin-right: 0;
              }
            }

            .alarm-email-input {
              @media (max-width: $breakpoint-mobile-1) {
                width: 100%;
              }
            }
          }

          .alarm-type-dropdown {
            min-width: 156px;
          }

          .alarm-country-code-dropdown {
            min-width: 113px;
          }
        }

        .alarm-delete-button {
          margin-left: var(--space-unit-xs);

          @media (max-width: $breakpoint-mobile-1) {
            margin-left: 0;
            width: fit-content;
          }
        }
      }

      .add-alarm-button {
        align-self: flex-end;
        margin-bottom: var(--space-unit-xl);
      }

      .alarm-list-item-divider {
        margin: 0 auto var(--space-unit-lg);
      }
    }
    .alarms-actions-divider {
      margin-bottom: var(--space-unit-xl);
    }
  }

  .alarms-actions {
    display: flex;
    margin-top: var(--space-unit-lg);

    @media (max-width: $breakpoint-mobile-1) {
      flex-direction: column;
    }

    & > * {
      flex: 1;

      &:last-child {
        margin-left: var(--space-unit-md);

        @media (max-width: $breakpoint-mobile-1) {
          margin-left: 0;
          margin-top: var(--space-unit-md);
        }
      }
    }

    .save-button {
      display: flex;
      width: 100%;

      button {
        flex: 1;
      }
    }
  }
}
